import React, { useState } from 'react'
import { compose } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { FormGroup, Label, Button, Input, ButtonGroup } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { HiOutlineTrash } from 'react-icons/hi'
import { selectors } from 'features/dashboard'
import {
    canRedo,
    canUndo,
    createStation,
    getMapStations,
    getZonesPaths,
    updateStation,
} from 'features/dashboard/Map.actionTypes'
import { handleConversion } from './helpers'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import DropdownSelect from 'layout-components/Dropdown'

const StationModal = ({
    status,
    toggle,
    station = {},
    categories,
    areaUuid,
    slug,
    handleNewPoint,
    setTraversalCreate,
    setWindows,
}) => {
    const dispatch = useDispatch()
    const vehicles = useSelector(selectors.getVehicles)
    const [stationType, setStationType] = useState('')
    const [tab, setTab] = useState('general')
    const [active, setActive] = useState(true)
    const [oldColor, setOldColor] = useState('#1569a8')
    const iconColor = station?.icon_color ? station?.icon_color : '#BA4FFA'

    const [category, setCategory] = useState(
        (station?.allowed_vehicle_categories &&
            categories?.find((cat) => cat.uuid === station?.allowed_vehicle_categories[0])
                ?.title) ||
            []
    )

    const filteredCategories = [{ title: 'No cateogry' }, ...categories.map((cat) => cat)]
    const [robotPositioningInput, setRobotPositioningInput] = useState('')

    const setInitialValues = () => {
        if (tab === 'general' && status === 'edit')
            return {
                ...station,
                robotPositioning: false,
                allowed_vehicle_categories: [],
            }
        else
            return {
                uuid: station.uuid,
                icon_color: station.icon_color,
                apply_color_choice_to: station.apply_color_choice_to,
            }
    }

    const handleSubmit = (data) => {
        let robotData
        let {
            uuid,
            robotPositioning,
            vehicle,
            points_in_meters,
            icon_color,
            apply_color_choice_to,
            points,
            ...rest
        } = data
        if (robotPositioning) {
            const vehicleUuid = vehicles.teamVehicle.filter((veh) => veh.uuid === vehicle)
            robotData = {
                ...rest,
                vehicle,
                rotation: Math.round(vehicleUuid[0].details.rotation)
                    ? Math.round(vehicleUuid[0].details.rotation)
                    : 0,
                points: [vehicleUuid[0].details.xd, vehicleUuid[0].details.yd],
                ...(vehicleUuid[0].category && {
                    allowed_vehicle_categories: [vehicleUuid[0].category],
                }),
            }
        }

        if (status === 'edit') {
            dispatch(
                updateStation({
                    uuid,

                    data: icon_color
                        ? { uuid, icon_color, apply_color_choice_to }
                        : handleConversion(
                              robotPositioning
                                  ? { ...robotData, robotPositioning }
                                  : { ...data, points_in_meters }
                          ),
                })
            ).then((res) => {
                if (res.error) return
                dispatch(getMapStations({ uuid: areaUuid }))
                dispatch(getZonesPaths(areaUuid))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
            })
        }
        if (status === 'add') {
            if (points_in_meters || robotPositioning) {
                dispatch(getTeamVehicles({ slug, text: 'status=true' })).then((res) => {
                    dispatch(
                        createStation({
                            uuid: areaUuid,
                            data: handleConversion(
                                robotPositioning
                                    ? { ...data, robotPositioning }
                                    : { ...rest, points_in_meters }
                            ),
                        })
                    ).then((res) => {
                        if (res.error) return
                        dispatch(getMapStations({ uuid: areaUuid }))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                        toggle()
                    })
                })
            } else {
                if (data.station_type === 'traversal') setTraversalCreate(true)
                const station = { ...rest, points }
                handleNewPoint(station)
            }
        }
        setActive(true)
        toggle()
    }
    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    src="/svgs/modal-icons/modal-station-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            {/* <h5>
                <strong className="modals-header">
                    {status === 'edit' ? <>Edit station</> : <>New station</>}
                </strong>
            </h5> */}
            <div>
                <span className="org-modal-header">
                    {status === 'add-vehicle' ? 'New station' : 'Station settings'}
                </span>

                <div className="modals-container-buttons">
                    <ButtonGroup className="org-button-group">
                        <Button
                            className={`${
                                tab === 'general'
                                    ? ' org-button-selected weight-500 size-14 grey-800'
                                    : ''
                            } org-button`}
                            style={{ borderRadius: '6px' }}
                            onClick={() => {
                                setTab('general')
                            }}
                        >
                            General
                        </Button>
                        {status === 'edit' && (
                            <Button
                                className={`${
                                    tab === 'appearance'
                                        ? ' org-button-selected weight-500 size-14 grey-800'
                                        : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('appearance')
                                }}
                            >
                                Appearance
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
            <Formik initialValues={setInitialValues()} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        {tab === 'general' && (
                            <>
                                <FormGroup>
                                    <Label className="modals-labels">Name</Label>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="modals-inputs"
                                        placeholder="Station name"
                                        component={customInputForm}
                                        value={values.title || ''}
                                    ></Field>
                                </FormGroup>

                                {status === 'add' ? (
                                    <>
                                        <FormGroup>
                                            <Label className="modals-labels">Type *</Label>
                                            <DropdownSelect
                                                selected={stationType}
                                                setSelected={setStationType}
                                                options={[
                                                    { uuid: 'default', name: 'Default' },
                                                    { uuid: 'traversal', name: 'Traversal' },
                                                ]}
                                                setFieldValue={setFieldValue}
                                                fieldValue="station_type"
                                            />
                                            {/* <Field
                                        type="select"
                                        component={customInputForm}
                                        name="station_type"
                                        className="modals-inputs"
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="default">Default</option>
                                        <option value="traversal">Traversal</option>
                                    </Field> */}
                                        </FormGroup>
                                    </>
                                ) : null}
                                {values.station_type !== 'traversal' ? (
                                    <>
                                        <FormGroup check className="ml-2 mb-0">
                                            <Input
                                                type="checkbox"
                                                id="robotPositioning"
                                                checked={values.robotPositioning}
                                                onChange={() => {
                                                    setFieldValue(
                                                        'robotPositioning',
                                                        !values.robotPositioning
                                                    )
                                                }}
                                            />
                                            <Label className="modals-labels mt-1">
                                                {' '}
                                                Use robot positioning
                                            </Label>
                                        </FormGroup>

                                        {values.robotPositioning ? (
                                            <FormGroup>
                                                <Label for="rotation">Connected robots</Label>
                                                <DropdownSelect
                                                    selected={robotPositioningInput}
                                                    setSelected={setRobotPositioningInput}
                                                    options={vehicles.teamVehicle.filter(
                                                        (vehicle) => vehicle.details
                                                    )}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue="vehicle"
                                                />
                                                {/* <Field
                                            component={customInputForm}
                                            type="select"
                                            name="vehicle"
                                            className="modals-inputs"
                                        >
                                            <option value=""></option>
                                            {vehicles.teamVehicle.map(
                                                ({ details, ...vehicle }) =>
                                                    details && (
                                                        <option
                                                            key={vehicle.uuid}
                                                            value={vehicle.uuid}
                                                        >
                                                            {vehicle.verbose_name}
                                                        </option>
                                                    )
                                            )}
                                        </Field> */}
                                            </FormGroup>
                                        ) : (
                                            <>
                                                <FormGroup>
                                                    <Label className="modals-labels">
                                                        Vehicle category
                                                    </Label>
                                                    <DropdownSelect
                                                        selected={category}
                                                        setSelected={setCategory}
                                                        options={filteredCategories}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue="allowed_vehicle_categories[0]"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="modals-labels">
                                                        Position
                                                    </Label>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Field
                                                            className="mr-1 modals-inputs"
                                                            type="number"
                                                            placeholder="Metric X"
                                                            step={0.00001}
                                                            name="points_in_meters[0]"
                                                            component={customInputForm}
                                                        />
                                                        <Field
                                                            className="ml-1 modals-inputs"
                                                            type="number"
                                                            step={0.00001}
                                                            placeholder="Metric Y"
                                                            name="points_in_meters[1]"
                                                            component={customInputForm}
                                                        />
                                                    </div>
                                                    {!station?.uuid && (
                                                        <span className="small-text">
                                                            If position is not entered you can drag
                                                            and drop on the map
                                                        </span>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="modals-labels">
                                                        Rotation
                                                    </Label>
                                                    <Field
                                                        className="modals-inputs"
                                                        component={customInputForm}
                                                        step={0.1}
                                                        type="number"
                                                        name="rotation"
                                                        placeholder="0°"
                                                        value={values.rotation}
                                                    />
                                                </FormGroup>
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </>
                        )}
                        {tab === 'appearance' && (
                            <>
                                <FormGroup>
                                    <Label className="grey-900 weight-500 size-14">
                                        Station colour
                                    </Label>
                                    <label className="color-selector modals-inputs">
                                        <span
                                            className="circle"
                                            style={{ background: active ? iconColor : oldColor }}
                                        />
                                        <span className="grey-400">
                                            {active ? iconColor : oldColor}
                                        </span>
                                        <Field
                                            style={{
                                                marginLeft: '100px',
                                            }}
                                            type="color"
                                            id="icon_color"
                                            name="icon_color"
                                            value={values.icon_color || ''}
                                            onChange={(e) => {
                                                const color = e.target.value
                                                setOldColor(color)
                                                setActive(!active)
                                                setFieldValue('icon_color', color)
                                            }}
                                            className="hidden"
                                        />
                                    </label>
                                </FormGroup>
                                <div className="d-flex">
                                    <FormGroup switch className="d-flex flex-column mb-2 ml-3">
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            role="switch"
                                            type="switch"
                                            id="apply_color_choice_to"
                                            className="custom-switch-form modals-labels"
                                            checked={values.apply_color_choice_to || ''}
                                            name="apply_color_choice_to"
                                            onChange={(e) => {
                                                setFieldValue(e.target.name, 'all')
                                            }}
                                        />
                                        <Label check className="mt-1" style={{ marginLeft: '8px' }}>
                                            Apply to all stations
                                        </Label>
                                    </FormGroup>
                                </div>
                            </>
                        )}

                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 ${
                                status === 'edit'
                                    ? 'justify-content-between'
                                    : 'justify-content-end'
                            } align-items-center`}
                        >
                            {status === 'edit' && tab === 'general' ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setWindows({ removeStation: true })
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete
                                </Button>
                            ) : (
                                <Button
                                    className="reset-button modals-delete-btn"
                                    color="none"
                                    onClick={() => {
                                        setOldColor('#BA4FFA')
                                        setActive(!active)
                                        setFieldValue('icon_color', '#BA4FFA')
                                    }}
                                >
                                    <img
                                        src="/svgs/map-layer/reset-btn.svg"
                                        alt="reset-icon"
                                        width="21px"
                                        height="21px"
                                    />

                                    <span style={{ marginLeft: '1px' }}> Reset to default</span>
                                </Button>
                            )}

                            <div className="d-flex">
                                <Button
                                    color="none"
                                    onClick={toggle}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                {status === 'edit' ? (
                                    <Button
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        disabled={
                                            JSON.stringify(station) === JSON.stringify(values)
                                        }
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        disabled={
                                            values.station_type === 'traversal'
                                                ? false
                                                : !values.station_type
                                        }
                                    >
                                        Create station
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

function mapStateToProps(state) {
    return {
        categories: selectors.getVehiclesCategoires(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(StationModal)
