import { ADMIN_PERMISSIONS } from 'export'

export const getOrg = (state) => state.dashboard.org
export const getCustom = (state) => state.dashboard.org.customeValue
export const getAdminOrgs = (state) => ({
    ...state.dashboard.org,
    list: state.dashboard.org.list?.filter(
        (org) => ADMIN_PERMISSIONS.findIndex((perm) => perm === org.membership_type) > -1
    ),
})
export const getTeam = (state) => state.dashboard.team
export const getTeamType = (state) => state.dashboard.team.details?.operation_type
export const getCategories = (state) => state.dashboard.org.categories
export const getOrgMembers = (state) => state.dashboard.org.results
export const getOrgStatus = (state) => state.dashboard.org
export const getOrgManufacturer = (state) => state.dashboard.org.manufacturer
export const getTaskError = (state) => state.dashboard.task.errors
export const getTeamError = (state) => state.dashboard.team.errors
export const getTeamMembers = (state) => state.dashboard.team.results
export const getTeamStatus = (state) => state.dashboard.team
export const getMapPoints = (state) => state.dashboard.team.teamMap.points
export const getMapStations = (state) => state.dashboard.team.teamMap.stations
export const getZonesPaths = (state) => state.dashboard.team.teamMap.zonesPaths
export const getOrgWebhooks = (state) => state.dashboard.org.webhooks
export const getOrganizationTeams = (state) => state.dashboard.org.teams
export const getTask = (state) => state.dashboard.task 
export const getFormValues = (state) => state.dashboard.task.formValues
export const getPreset = (state) => state.dashboard.task.preset
export const getTeamWebhooks = (state) => state.dashboard.team.webhooks
export const getTeamMap = (state) => state.dashboard.team.teamMap
export const getTeamPathPlanning = (state) => state.dashboard.team.path_planning
export const getMapError = (state) => state?.dashboard.team.teamMap?.errors
export const getVehicles = (state) => state.dashboard.team.vehicles
export const getTriggers = (state) => state.dashboard.team.teamTriggers
export const getVariable = (state) => state.dashboard.team.forms.variables
export const getVariableValues = (state) => state.dashboard.team.forms.variableValue
export const getFormErrors = (state) => state.dashboard.team.forms.errors
export const getGenericForm = (state) => state.dashboard.team.forms.customForm
export const getOrgForm = (state) => state.dashboard.team.forms.orgForm
export const getVehiclesCategoires = (state) => state.dashboard.team.vehicles.categories
export const getMembers = (state) => state.dashboard.team.members
export const getAnalytics = (state) => state.dashboard.team.analytics
export const getSavedViewData =(state)=> state.dashboard.team.analytics.cardData
export const getFilteredData =(state)=> state.dashboard.team.analytics.filterdata
export const getDashViews =(state)=> state.dashboard.team.analytics.dashboardView
export const getDashboards =(state)=> state.dashboard.team.analytics.dashboards
export const getallCard =(state) => state.dashboard.team.analytics.cardlist
export const getWhitedevDashboard = (state) => state.dashboard.team.whitedevDashboard
export const getSetup = (state) => state.dashboard.team.setup
export const getNotification = (state) => state.dashboard.notification
export const getUser = (state) => state.user
export const getBilling = (state) => state.generic.billing
export const getDevices = (state) => state.dashboard.team.setup.device_models
export const getDevicess = (state) => state.dashboard.team.setup.devices
export const getFinalizedRecording = (state) => state.dashboard.team.finalizedRecording
export const getReleaseNotes = (state) => state.dashboard.org.release_notes
// export const getSeperatedFigures = (state) =>
//     state.dashboard.team.teamMap.figures.reduce(
//         (acc, fig) => {
//             if (fig.figure_type === 'area') {
//                 acc.area = {
//                     ...fig,
//                     details: state.dashboard.team.teamMap.areaDetails,
//                 }
//             }
//             if (fig.figure_type === 'obstacle') acc.obstacles.push(fig)
//             if (fig.figure_type === 'safezone') acc.safe_zones.push(fig)
//             return acc
//         },
//         {
//             area: { points: [], details: {} },
//             obstacles: [],
//             safe_zones: [],
//         }
//     )

