import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field } from 'formik'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    Input,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Table,
    UncontrolledDropdown,
} from 'reactstrap'
import { customInputForm } from 'ui/common'
import { updateFormValues } from 'features/dashboard/Task.actionTypes'

export const RenderDeviceComponents = ({ deviceComponents, index, activeTab }) => {
    return (
        <>
            <Label className="modals-labels">Device components</Label>
            <div className="d-flex flex-wrap">
                {Object.keys(deviceComponents).map((component, i) => (
                    <FormGroup key={i} className="px-2">
                        <Label className="modals-labels" for={component}>
                            {component.charAt(0).toUpperCase() + component.slice(1)}
                        </Label>
                        <Field
                            id={component}
                            component={customInputForm}
                            className="modals-inputs"
                            type="text"
                            name={
                                activeTab === 'actions-presets'
                                    ? `device_components[${component}]`
                                    : `device_components[${index}][${component}]`
                            }
                            value={deviceComponents[component]}
                        />
                    </FormGroup>
                ))}
            </div>
        </>
    )
}

export const RenderFormValues = ({ task, index, activeTab, setFieldValue }) => {
    const [checkValue, setCheckValue] = useState()

    const handleTrueInputChange = (prop) => {
        setCheckValue(true)
        if (activeTab === 'actions-presets' && task.form_values) {
            setFieldValue(`definition.form_values.${prop}`, true)
        } else {
            setFieldValue(`definition[${index}].form_values.${prop}`, true)
        }
    }

    const handleFalseInputChange = (prop) => {
        setCheckValue(false)
        if (activeTab === 'actions-presets' && task.form_values) {
            setFieldValue(`definition.form_values.${prop}`, false)
        } else {
            setFieldValue(`definition[${index}].form_values.${prop}`, false)
        }
    }

    if (task.form) {
        return (
            <tr
                key={task.uuid}
                style={{
                    display: 'grid',
                    gridTemplateColumns:
                        task.slug === 'send_email' ? 'repeat(1, fr)' : 'repeat(3, 1fr)',
                    gridTemplateRows: 'auto',
                    gap: '10px',
                }}
                className="w-100 border-0 p-0 ml-2"
            >
                {task?.form?.variables.map((variable, i) => {
                    if (variable.variable_type === 'boolean') {
                        return (
                            <td key={i}>
                                <FormGroup>
                                    <Label className="modals-labels" for={variable.name}>
                                        {variable.name}
                                    </Label>
                                    <div className="ml-4">
                                        <FormGroup>
                                            <Input
                                                inline
                                                type="checkbox"
                                                id={`${variable.name}-true`}
                                                checked={checkValue === true}
                                                className="modals-labels"
                                                name={`definition[${index}].form_values.${variable.variable_name}`}
                                                onChange={() =>
                                                    handleTrueInputChange(variable.variable_name)
                                                }
                                            />
                                            <Label check>True</Label>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                inline
                                                type="checkbox"
                                                id={`${variable.name}-false`}
                                                checked={checkValue === false}
                                                className="modals-labels"
                                                name={`definition[${index}].form_values.${variable.variable_name}`}
                                                onChange={() =>
                                                    handleFalseInputChange(variable.variable_name)
                                                }
                                            />
                                            <Label check>False</Label>
                                        </FormGroup>
                                    </div>
                                </FormGroup>
                            </td>
                        )
                    }
                    return (
                        <td key={i}>
                            <Label className="modals-labels" for={variable.name}>
                                {variable.variable_name.charAt(0).toUpperCase() +
                                    variable.variable_name.slice(1)}{' '}
                                {variable.required && '*'}
                            </Label>
                            <Field
                                id={variable.variable_name}
                                component={customInputForm}
                                className="modals-inputs"
                                type={variable.variable_type}
                                name={`definition[${index}].form_values.${variable.variable_name}`}
                                required={variable.required}
                            />
                        </td>
                    )
                })}
            </tr>
        )
    }

    if (task.form_values && !task.form) {
        if (Object.values(task.form_values).length === 0) return null
        return (
            <tr
                key={task.uuid}
                style={{
                    display: 'grid',
                    gridTemplateColumns:
                        task.slug === 'send_email' ? 'repeat(1, fr)' : 'repeat(3, 1fr)',
                    gridTemplateRows: 'auto',
                    gap: '10px',
                }}
                className="w-100 border-0 p-0 ml-2"
            >
                {task.form_variables.map((variable, i) => {
                    const capitalProp =
                        variable.variable_name.charAt(0).toUpperCase() +
                        variable.variable_name.slice(1)
                    return (
                        <td key={i}>
                            {typeof variable.value === 'boolean' ? (
                                <FormGroup>
                                    <Label className="modals-labels" for={variable.variable_name}>
                                        {capitalProp}
                                    </Label>
                                    <div className="ml-4">
                                        <FormGroup>
                                            <Input
                                                inline
                                                type="checkbox"
                                                id={`${variable.variable_name}-true`}
                                                checked={variable.value === true}
                                                label="True"
                                                className="modals-labels"
                                                name={
                                                    activeTab !== 'actions-presets'
                                                        ? `definition[${index}].form_values.${variable.variable_name}`
                                                        : `definition.form_values.${variable.variable_name}`
                                                }
                                                onChange={(e) =>
                                                    handleTrueInputChange(variable.variable_name)
                                                }
                                            />
                                            <Label>True</Label>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                inline
                                                type="checkbox"
                                                id={`${variable.variable_name}-false`}
                                                checked={variable.value === false}
                                                label="False"
                                                className="modals-labels"
                                                name={
                                                    activeTab !== 'actions-presets'
                                                        ? `definition[${index}].form_values.${variable.variable_name}`
                                                        : `definition.form_values.${variable.variable_name}`
                                                }
                                                onChange={(e) =>
                                                    handleFalseInputChange(variable.variable_name)
                                                }
                                            />
                                            <Label check> False </Label>
                                        </FormGroup>
                                    </div>
                                </FormGroup>
                            ) : (
                                <>
                                    <Label className="modals-labels" for={variable.variable_name}>
                                        {capitalProp} {variable.required && '*'}
                                    </Label>
                                    <Field
                                        id={variable.variable_name}
                                        type={
                                            variable.value === 'float' ||
                                            variable.value === 'integer'
                                                ? 'number'
                                                : 'text'
                                        }
                                        component={customInputForm}
                                        className="modals-inputs"
                                        name={
                                            activeTab !== 'actions-presets'
                                                ? `definition[${index}].form_values.${variable.variable_name}`
                                                : `definition.form_values.${variable.variable_name}`
                                        }
                                        value={variable.value}
                                        required={variable.required}
                                    />
                                </>
                            )}
                        </td>
                    )
                })}
            </tr>
        )
    }
}

const RenderDropDown = ({
    setFieldValue,
    definitionValues,
    device_state,
    auto_confirmation,
    pointValues,
    actions,
    property,
    stations,
    devices,
    activeTab,
    values,
}) => {
    const dispatch = useDispatch()
    let subtasks = definitionValues
    const [selectedOptions, setSelectedOptions] = useState([...pointValues])
    let deviceState = device_state

    const isNotSameToLastItem = (uuid) => {
        const lastPoint = subtasks[subtasks.length - 1]
        return lastPoint !== uuid || !lastPoint
    }

    const handleOnDragEnd = (result) => {
        const items = [...subtasks]
        const stations = [...pointValues]
        const deviceStates = [...device_state]
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        subtasks = items
        setFieldValue(property, items)
        const [reorderedStation] = stations.splice(result.source.index, 1)
        stations.splice(result.destination.index, 0, reorderedStation)
        setSelectedOptions([...stations])
        setFieldValue(`point`, stations)
        const [reorderedDeviceState] = deviceStates.splice(result.source.index, 1)
        deviceStates.splice(result.destination.index, 0, reorderedDeviceState)
        deviceState = deviceStates
        setFieldValue(`device_state`, deviceStates)
    }

    const showDeviceStationorPath = (definitionValues, pointValues, index) => {
        switch (true) {
            case definitionValues[index]?.device_type === 'iot':
                return (
                    <FormGroup className="px-2">
                        <Label className="modals-labels">Devices</Label>
                        <Field
                            component={customInputForm}
                            type="select"
                            className="modals-inputs"
                            name={`point[${index}]`}
                            value={pointValues[index] || ''}
                            onChange={(e) => {
                                setFieldValue(`point[${index}]`, e.target.value)
                                const device = devices?.find(
                                    (device) => device.uuid === e.target.value
                                )
                                if (device.components.length > 0) {
                                    setFieldValue(
                                        `device_components[${index}]`,
                                        device.components.reduce((acc, component) => {
                                            acc[component.identifier] = component.value
                                            return acc
                                        }, {})
                                    )
                                } else {
                                    setFieldValue(`device_components`, [])
                                }
                                if (device.states.length > 0) {
                                    setFieldValue(
                                        `device_state[${index}]`,
                                        device.states.reduce((acc, state) => {
                                            acc[state.title] = state.payload
                                            return acc
                                        }, {})
                                    )
                                } else {
                                    setFieldValue(`device_state`, [])
                                }
                            }}
                        >
                            <option value="">Pick device</option>

                            {devices.map((device) => (
                                <option key={device.uuid} value={device.uuid}>
                                    {device.identifier}
                                </option>
                            ))}
                        </Field>
                    </FormGroup>
                )
            case definitionValues[index]?.device_type === 'robot' &&
                definitionValues[index].slug !== 'follow_path':
                return (
                    <FormGroup className="px-2">
                        <Label className="modals-labels">Stations</Label>
                        <Field
                            component={customInputForm}
                            type="select"
                            className="modals-inputs"
                            name={`point[${index}]`}
                            value={pointValues[index] || ''}
                        >
                            <option value="">Choose a station</option>
                            {stations.map((station) => (
                                <option key={station.uuid} value={station.uuid}>
                                    {station.title}
                                </option>
                            ))}
                        </Field>
                    </FormGroup>
                )
            case definitionValues[index]?.device_type === 'robot' &&
                definitionValues[index].slug === 'follow_path':
                return (
                    <FormGroup className="px-2">
                        <Label className="modals-labels">Stations</Label>
                        <Field
                            component={customInputForm}
                            // required={pointValues.length === 0 ? true : false}
                            type="select"
                            className="modals-inputs"
                            multiple
                            name={`point[${index}]`}
                            value={pointValues[index] || ''}
                        >
                            {stations.map((station) => (
                                <option
                                    key={station.uuid}
                                    value={station.uuid}
                                    onClick={() => {
                                        const updatedOptions = [...selectedOptions]

                                        // Check if the inner array at the specified index exists
                                        if (updatedOptions[index]) {
                                            updatedOptions[index] = [
                                                ...updatedOptions[index],
                                                station,
                                            ] // Push station into the inner array
                                        } else {
                                            updatedOptions[index] = [station] // If the inner array doesn't exist, create a new one with station
                                        }

                                        setSelectedOptions(updatedOptions) // Set the state with the updated array
                                        setFieldValue(`point[${index}]`, updatedOptions[index])
                                    }}
                                >
                                    {station.title}
                                </option>
                            ))}
                        </Field>
                        <small className="text-meili">
                            You can select several stations in a specific sequence.
                        </small>
                        {selectedOptions.length > 0 &&
                            selectedOptions[index] &&
                            selectedOptions[index].map((station, i) => (
                                <div
                                    key={i}
                                    className="d-flex justify-content-between mb-3 w-100 align-items-center px-3"
                                >
                                    <span>{i + 1}.</span>
                                    <span className="">{station.title}</span>
                                    <Button
                                        onClick={() => {
                                            // Create a deep copy of the selectedOptions array
                                            const updatedOptions = selectedOptions.map(
                                                (innerArray) => [...innerArray]
                                            )

                                            // Check if the inner array at the specified index exists
                                            if (updatedOptions[index]) {
                                                // Remove the element at index i from the inner array
                                                updatedOptions[index].splice(i, 1)
                                            }

                                            // Set the state with the updated array
                                            setSelectedOptions(updatedOptions)
                                            setFieldValue(`point[${index}]`, updatedOptions[index])
                                        }}
                                        color="link"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            ))}
                    </FormGroup>
                )
            default:
                return
        }
    }

    const showActionPresetsDeviceStationPath = (definitionValues, pointValues, index) => {
        switch (true) {
            case definitionValues?.device_type === 'iot':
                return (
                    <FormGroup className="px-2">
                        <Label className="modals-labels">Devices</Label>
                        <Field
                            component={customInputForm}
                            type="select"
                            name="point"
                            className="modals-inputs"
                            value={pointValues || ''}
                            onChange={(e) => {
                                setFieldValue('point', e.target.value)
                                const device = devices?.find(
                                    (device) => device.uuid === e.target.value
                                )
                                if (device.components.length > 0) {
                                    setFieldValue(
                                        `device_components`,
                                        device.components.reduce((acc, component) => {
                                            acc[component.identifier] = component.value
                                            return acc
                                        }, {})
                                    )
                                } else {
                                    setFieldValue(`device_components`, [])
                                }
                                if (device.states.length > 0) {
                                    setFieldValue(
                                        `device_state`,
                                        device.states.reduce((acc, state) => {
                                            acc[state.title] = state.payload
                                            return acc
                                        }, null)
                                    )
                                } else {
                                    setFieldValue(`device_state`, [])
                                }
                            }}
                        >
                            <option value="">Devices</option>
                            {devices.map((device) => (
                                <option key={device.uuid} value={device.uuid}>
                                    {device.identifier}
                                </option>
                            ))}
                        </Field>
                    </FormGroup>
                )
            case definitionValues?.device_type === 'robot' &&
                definitionValues.slug !== 'follow_path':
                return (
                    <FormGroup className="px-2">
                        <Label className="modals-labels">Stations</Label>
                        <Field
                            component={customInputForm}
                            type="select"
                            name="point"
                            className="modals-inputs"
                            value={pointValues || ''}
                        >
                            <option value="">Stations</option>
                            {stations.map((station) => (
                                <option key={station.uuid} value={station.uuid}>
                                    {station.title}
                                </option>
                            ))}
                        </Field>
                    </FormGroup>
                )
            case definitionValues?.device_type === 'robot' &&
                definitionValues.slug === 'follow_path':
                return (
                    <FormGroup className="px-2">
                        <Label className="modals-labels">Stations</Label>
                        <Field
                            component={customInputForm}
                            // required={pointValues.length === 0 ? true : false}
                            type="select"
                            name="point"
                            className="modals-inputs"
                            multiple
                            value={pointValues || ''}
                        >
                            {stations.map((station) => (
                                <option
                                    key={station.uuid}
                                    value={station.uuid}
                                    onClick={() => {
                                        const updatedOptions = [...selectedOptions]
                                        updatedOptions.push(station)
                                        setSelectedOptions([...updatedOptions])
                                        setFieldValue(`point`, updatedOptions)
                                    }}
                                >
                                    {station.title}
                                </option>
                            ))}
                        </Field>
                        <small className="text-meili">
                            You can edit multiple stations by order.
                        </small>
                        {selectedOptions.length > 0 &&
                            selectedOptions.map((station, i) => (
                                <div
                                    key={i}
                                    className="d-flex justify-content-between mb-3 w-100 align-items-center px-3"
                                >
                                    <span>{i + 1}.</span>
                                    <span className="">{station.title}</span>
                                    <Button
                                        onClick={() => {
                                            const updatedOptions = [...selectedOptions]
                                            updatedOptions.splice(i, 1)
                                            setSelectedOptions([...updatedOptions])
                                            setFieldValue(`point`, updatedOptions)
                                        }}
                                        color="link"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            ))}
                    </FormGroup>
                )
            default:
                return
        }
    }

    const showDeviceStates = (uuid) => {
        const device = devices?.find((device) => device.uuid === uuid)
        return device?.states.map((state) => (
            <option key={state.uuid} value={state.uuid}>
                {state.title}
            </option>
        ))
    }

    return (
        <>
            <UncontrolledDropdown
                style={{
                    position: 'sticky',
                    top: '30px',
                    zIndex: '1000',
                    backgroundColor: 'white',
                }}
                disabled={activeTab === 'actions-presets' && definitionValues.length !== 0}
            >
                <DropdownToggle
                    style={{
                        border: '1px solid var(--grey-100, #e7e9ec)',
                        borderRadius: '8px',
                        padding: '8px 12px',
                        fontSize: '16px',
                        height: '40px',
                        width: '100%',
                        background: 'white',
                        color: '#ccc',
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <span></span>
                    <FontAwesomeIcon color="black" size="xs" icon={faChevronDown} />
                </DropdownToggle>
                <DropdownMenu
                    className="col-12 scrolable"
                    style={{ overflow: 'auto', maxHeight: 200 }}
                >
                    {actions?.map((item, i) => (
                        <DropdownItem
                            key={item.uuid}
                            onClick={() => {
                                if (isNotSameToLastItem(item.uuid)) {
                                    if (item.slug === 'move_to_point' && activeTab === 'presets') {
                                        deviceState.push('')
                                        setFieldValue('device_state', deviceState)
                                    }
                                    subtasks.push(item)
                                    setFieldValue(property, subtasks)
                                }
                            }}
                            value={item.uuid}
                        >
                            {item.displayable_name.charAt(0).toUpperCase() +
                                item.displayable_name.toLowerCase().slice(1)}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <Table hover>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppableActions">
                        {(provided) => (
                            <tbody
                                data-testid="picked-points"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {activeTab === 'actions-presets' && definitionValues.uuid ? (
                                    <div
                                        className="p-4 mt-2"
                                        style={{ borderBottom: '1px solid lightgray' }}
                                    >
                                        <div
                                            className={`d-flex ${
                                                !definitionValues.uuid
                                                    ? 'justify-content-between'
                                                    : 'justify-content-center'
                                            }  mb-3 w-100 align-items-center`}
                                        >
                                            {!definitionValues.uuid && <span>-</span>}

                                            <span>{subtasks.displayable_name}</span>
                                            {!definitionValues.uuid && (
                                                <Button
                                                    onClick={() => {
                                                        subtasks = []
                                                        setFieldValue(property, subtasks)
                                                        dispatch(updateFormValues(0))
                                                    }}
                                                    color="link"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            )}
                                        </div>

                                        {showActionPresetsDeviceStationPath(
                                            definitionValues,
                                            pointValues,
                                            0
                                        )}

                                        <RenderFormValues
                                            task={subtasks}
                                            activeTab={activeTab}
                                            setFieldValue={setFieldValue}
                                            index={0}
                                        />

                                        {definitionValues?.device_type === 'iot' && (
                                            <>
                                                <FormGroup className="px-2">
                                                    <Label className="modals-labels">
                                                        Device states
                                                    </Label>
                                                    <Field
                                                        component={customInputForm}
                                                        required
                                                        type="select"
                                                        className="modals-inputs"
                                                        name={`device_state`}
                                                        value={deviceState || ''}
                                                    >
                                                        <option value="">Pick device state</option>
                                                        {showDeviceStates(pointValues)}
                                                    </Field>
                                                </FormGroup>
                                                <RenderDeviceComponents
                                                    deviceComponents={values.device_components}
                                                    activeTab={activeTab}
                                                />
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    subtasks?.map((task, index) => (
                                        <Draggable
                                            key={index}
                                            index={index}
                                            draggableId={index.toString()}
                                            className="p-4"
                                            style={{
                                                borderBottom: '1px solid lightgray',
                                                backgroundColor: 'white',
                                                width: '100%',
                                                height: 'auto',
                                            }}
                                        >
                                            {(provided) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <div className="d-flex justify-content-between mb-3 w-100 align-items-center mt-2">
                                                        <span>
                                                            {activeTab !== 'actions-presets'
                                                                ? index + 1
                                                                : '-'}
                                                        </span>
                                                        <span className="">
                                                            {task.displayable_name}
                                                        </span>
                                                        <Button
                                                            onClick={() => {
                                                                const newPointValues = [
                                                                    ...pointValues,
                                                                ]
                                                                subtasks?.splice(index, 1)
                                                                newPointValues?.splice(index, 1)
                                                                deviceState?.splice(index, 1)
                                                                setFieldValue(property, subtasks)
                                                                setFieldValue(
                                                                    'point',
                                                                    newPointValues
                                                                )
                                                                setFieldValue(
                                                                    'device_state',
                                                                    deviceState
                                                                )
                                                                dispatch(updateFormValues(index))
                                                            }}
                                                            color="link"
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </div>

                                                    <>
                                                        {showDeviceStationorPath(
                                                            subtasks,
                                                            pointValues,
                                                            index
                                                        )}
                                                        <RenderFormValues
                                                            task={task}
                                                            index={index}
                                                            activeTab={activeTab}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                        {task?.device_type === 'iot' && (
                                                            <>
                                                                {device_state &&
                                                                    device_state.length > 0 && (
                                                                        <FormGroup className="px-2">
                                                                            <Label className="modals-labels">
                                                                                Device states
                                                                            </Label>
                                                                            <Field
                                                                                component={
                                                                                    customInputForm
                                                                                }
                                                                                required
                                                                                type="select"
                                                                                className="modals-inputs"
                                                                                name={`device_state[${index}]`}
                                                                                value={
                                                                                    activeTab ===
                                                                                    'actions-presets'
                                                                                        ? device_state
                                                                                        : deviceState[
                                                                                              index
                                                                                          ]
                                                                                }
                                                                            >
                                                                                <option value="">
                                                                                    Pick device
                                                                                    state
                                                                                </option>
                                                                                {showDeviceStates(
                                                                                    pointValues[
                                                                                        index
                                                                                    ]
                                                                                )}
                                                                            </Field>
                                                                        </FormGroup>
                                                                    )}

                                                                {values.device_components &&
                                                                    values.device_components
                                                                        .length > 0 && (
                                                                        <RenderDeviceComponents
                                                                            deviceComponents={
                                                                                values
                                                                                    .device_components[
                                                                                    index
                                                                                ]
                                                                            }
                                                                            index={index}
                                                                            activeTab={activeTab}
                                                                        />
                                                                    )}
                                                            </>
                                                        )}

                                                        {activeTab !== 'actions-presets' && (
                                                            <FormGroup
                                                                switch
                                                                style={{ marginLeft: '38px' }}
                                                            >
                                                                <Input
                                                                    style={{
                                                                        height: '20px',
                                                                        width: '36px',
                                                                    }}
                                                                    role="switch"
                                                                    type="switch"
                                                                    id={`auto_confirmation_${index}`}
                                                                    className="custom-switch-form modals-labels"
                                                                    name={`auto_confirmationSubtask[${index}]`}
                                                                    checked={
                                                                        auto_confirmation[index] ||
                                                                        false
                                                                    }
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            `auto_confirmationSubtask[${index}]`,
                                                                            !auto_confirmation[
                                                                                index
                                                                            ]
                                                                        )
                                                                    }}
                                                                />
                                                                <Label
                                                                    check
                                                                    className="mt-1 ml-3 modals-labels"
                                                                >
                                                                    {' '}
                                                                    Auto-confirmation
                                                                </Label>
                                                            </FormGroup>
                                                        )}
                                                    </>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                )}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </Table>
        </>
    )
}

export default RenderDropDown
