import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Progress } from 'reactstrap'
import { TITLE } from 'config'
import { AgGridReact } from 'ag-grid-react'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import {
    getOrgVehiclePage,
    removeVehicleFromOrg,
    resetVehicles,
} from 'features/dashboard/Vehicle.actionTypes'
import {
    getOrgVehicleCategories,
    getOrgTeams,
    deleteCategory,
} from 'features/dashboard/Organization.actionTypes'
import { getCategories, getOrganizationTeams, getVehicles } from 'features/dashboard/selectors'
import ManageOrganizationModals from './ManageOrganizationModals'
import Filter from 'layout-components/Filter'
import { HiOutlineX } from 'react-icons/hi'

const VehiclesCategoriesManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const vehicles = useSelector(getVehicles)
    const teams = useSelector(getOrganizationTeams)
    const categories = useSelector(getCategories)
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(null)
    const [dataOnClick, setDataOnClick] = useState({})
    const [activeTab, setActiveTab] = useState('vehicles')
    const [btnText, setButtonText] = useState('Add vehicle')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(0)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [initialFilterValues, setInitialFilterValues] = useState({
        team: [],
        category: [],
        status: '',
    })
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)

    const [searchText, setSearchText] = useState('')
    const gridRef = useRef()
    const [api, setApi] = useState(null)

    const onGridReady = useCallback(
        (params) => {
            gridRef.current = params.api // Store grid API in gridRef
            params.api.sizeColumnsToFit() // Resize columns once the grid is ready
            setApi(params.api)
            currentPageNumber > 1 && params.api.paginationGoToPage(lastClickedPage)
        },
        [lastClickedPage, currentPageNumber]
    )

    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPage(currentPage)
        }
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        api && api.onFilterChanged(value)
    }
    const defaultColDef = {
        cellClass: 'cell-class', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
        resizable: true,
        sortable: true,
        flex: 1,
    }

    useEffect(() => {
        dispatch(getOrgVehiclePage({ slug: currentOrg.slug, page: currentPageNumber }))
        dispatch(getOrgTeams(currentOrg.slug))
        dispatch(getOrgVehicleCategories(currentOrg))
    }, [currentOrg.slug, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1)
            dispatch(
                getOrgVehiclePage({
                    slug: currentOrg.slug,
                    page: currentPageNumber,
                    filters: initialFilterValues,
                })
            )
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (activeTab === 'vehicles') {
            document.title = `${TITLE} - Vehicles manage`
            setButtonText('Add vehicle')
        }
        if (activeTab === 'categories') {
            document.title = `${TITLE} - Categories manage`
            setButtonText('Add category')
        }
    }, [activeTab])

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal, dispatch])

    const handleRemoveFromOrg = ({ uuid }) => {
        if (activeTab === 'vehicles') {
            dispatch(removeVehicleFromOrg({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(
                        getOrgVehiclePage({
                            slug: currentOrg.slug,
                            page: currentPageNumber,
                            filters: initialFilterValues,
                        })
                    )
                }
                toggle()
            })
        }

        if (activeTab === 'categories') {
            dispatch(deleteCategory({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgVehicleCategories(currentOrg))
                }
                toggle()
            })
        }
    }

    const showBatteryColor = (level) => {
        switch (true) {
            case level < 25:
                return 'danger'
            case level <= 50:
                return 'warning'
            case level <= 100:
                return 'success'
            default:
                return 'info'
        }
    }

    const RenderBatteryLevel = ({ battery }) => {
        if (!battery) {
            return <div className="text-dark text-left">Currently not available</div>
        }
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Progress
                    value={battery}
                    color={showBatteryColor(battery)}
                    style={{ height: '8px', width: '280px', marginRight: '10px' }}
                />
                <div className="text-dark">{battery}%</div>
            </div>
        )
    }

    const RenderVehCatManage = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const list = useMemo(() => {
        return vehicles.results.map((vehicle) => ({
            uuid: vehicle.uuid,
            verboseName: vehicle?.verbose_name,
            team: vehicle.team_name || 'No team',
            teamUuid: vehicle.team,
            category: vehicle.category_name ? vehicle?.category_name : 'No category',
            status: vehicle.active ? (
                <span className="active-status status-cell">Active</span>
            ) : vehicle.is_charging ? (
                <span className="charging-status status-cell">Charging</span>
            ) : (
                <span className="inactive-status status-cell">Inactive</span>
            ),
            integration_type: vehicle.integration_type,
            battery: <RenderBatteryLevel battery={vehicle.battery_level} />,
            action: (
                <RenderVehCatManage
                    item={vehicle}
                    form={{ edit: 'edit-vehicle', remove: 'remove-vehicle' }}
                />
            ),
        }))
    }, [vehicles])

    const columnsList = [
        {
            field: 'verboseName',
            headerName: 'Name',
            sortable: true,
            unSortIcon: true,
        },
        {
            field: 'team',
            headerName: 'Team',
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (row) => {
                return (
                    <div
                        className="team-div-cell"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${row.data.team}/`,
                                state: {
                                    slug: row.data.team.teamUuid,
                                    team: row.data.team.teamUuid,
                                },
                            })
                        }
                    >
                        {row.data.team}
                    </div>
                )
            },
        },
        {
            field: 'category',
        },
        {
            field: 'status',
            headerName: 'Status',
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.status}</div>
            },
        },
        {
            field: 'integration_type',
            headerName: 'Integration type',
        },
        {
            field: 'battery',
            headerName: 'Battery',
            autoWidth: true,
            minWidth: 400,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.battery}</div>
            },
        },

        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            maxWidth: 100,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]

    const categoriesList = useMemo(() => {
        return categories.map((category) => ({
            uuid: category.uuid,
            title: category.title ? category.title : 'No title',
            maxLoad: category.max_load ? `${category.max_load}` : 'No max load',
            details: category.dimensions
                ? `${category.dimensions[0]}, ${category.dimensions[1]}, ${category.dimensions[2]} `
                : 'No details',
            vehicle_type: category.vehicle_type?.toUpperCase() ,
            action: (
                <RenderVehCatManage
                    item={category}
                    form={{ edit: 'edit-category', remove: 'remove-category' }}
                />
            ),
        }))
    }, [categories])

    const categoriesColumns = [
        {
            field: 'title',
            headerName: 'Title',
            sortable: true,
            unSortIcon: true,
        },
        {
            field: 'maxLoad',
            headerName: 'Max load (kg)',
        },
        {
            field: 'details',
            headerName: 'Dimensions (mm: L, W, H)',
        },
        {
            field: 'vehicle_type',
            headerName: 'Vehicle type',
        },
        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            maxWidth: 80,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="buttons-container-org">
                <ButtonGroup className="org-button-group">
                    <Button
                        className={`${
                            activeTab === 'vehicles' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setActiveTab('vehicles')
                            setSearchText('')
                        }}
                    >
                        Vehicles
                    </Button>
                    <Button
                        className={`${
                            activeTab === 'categories' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setActiveTab('categories')
                            setSearchText('')
                        }}
                    >
                        Categories
                    </Button>
                </ButtonGroup>
            </div>
            <div className="heading-container-org">
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">
                            {activeTab === 'vehicles' ? 'Vehicles' : 'Categories'}
                        </span>
                        {vehicles.status === 'loaded' && (
                            <span
                                className="text-meili rounded px-2"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {activeTab === 'vehicles'
                                    ? `${vehicles?.count} vehicles`
                                    : `${categories?.length} categories`}
                            </span>
                        )}
                    </div>
                    <span className="small-text-org">
                        {activeTab === 'vehicles'
                            ? ' Manage your vehicles and their settings here.'
                            : 'Manage your categories and their settings here.'}
                    </span>
                </div>
                <div className="d-flex">
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm(activeTab === 'vehicles' ? 'add-vehicle' : 'add-category')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        {btnText}
                    </Button>
                </div>
            </div>

            <div className="search-header">
                {' '}
                <input
                    type="text"
                    className="search-box"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearch}
                ></input>
                {activeTab === 'vehicles' && (
                    <Filter
                        table="vehicles"
                        slug={currentOrg.slug}
                        initValues={initialFilterValues}
                        setInitValues={setInitialFilterValues}
                        setCurrentPageNumber={setCurrentPageNumber}
                        setLastClickedPage={setLastClickedPage}
                        dropdownOpen={dropdownOpen}
                        toggle={dropdownToggle}
                        teams={teams}
                        categories={categories}
                    />
                )}
            </div>
            {vehicles.status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    {activeTab === 'vehicles' &&
                        [
                            ...initialFilterValues.team,
                            ...initialFilterValues.category,
                            initialFilterValues.status,
                        ].filter((item) => item !== '').length > 0 && (
                            <div className="d-flex align-items-start">
                                {[
                                    ...initialFilterValues.team,
                                    ...initialFilterValues.category,
                                    initialFilterValues.status,
                                ]
                                    .filter((item) => item !== '')
                                    .map((item, index) => (
                                        <div
                                            key={index}
                                            className="filter-style d-flex align-items-center text-meili"
                                            style={{
                                                backgroundColor: '#F9EFFE',
                                                marginRight: '8px',
                                            }}
                                        >
                                            <HiOutlineX
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setInitialFilterValues((prevValues) => {
                                                        const newTeam = prevValues.team.filter(
                                                            (t) => t !== item
                                                        )
                                                        const newCategory =
                                                            prevValues.category.filter(
                                                                (c) => c !== item
                                                            )
                                                        const newStatus =
                                                            prevValues.status === item
                                                                ? ''
                                                                : prevValues.status

                                                        const updatedValues = {
                                                            team: newTeam,
                                                            category: newCategory,
                                                            status: newStatus,
                                                        }

                                                        // Dispatch the API call with the updated values
                                                        dispatch(resetVehicles())
                                                        setCurrentPageNumber(1)
                                                        setLastClickedPage(0)
                                                        dispatch(
                                                            getOrgVehiclePage({
                                                                slug: currentOrg.slug,
                                                                filters: updatedValues,
                                                            })
                                                        )

                                                        return updatedValues
                                                    })
                                                }}
                                            />
                                            {item === 'null' ? 'Unassigned': item}
                                        </div>
                                    ))}
                            </div>
                        )}

                    <div
                        className="rounded-table-container ag-theme-quartz"
                        style={{ width: '100%' }}
                    >
                        {activeTab === 'vehicles' ? (
                            vehicles.results.length > 0 ? (
                                <AgGridReact
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    rowData={list}
                                    columnDefs={columnsList}
                                    onGridReady={onGridReady}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                    defaultColDef={defaultColDef}
                                    rowHeight={75}
                                    onPaginationChanged={getCurrentPage}
                                    quickFilterText={searchText}
                                    domLayout="autoHeight"
                                />
                            ) : (
                                <h5 className="p-4">There are currently no vehicles.</h5>
                            )
                        ) : null}

                        {activeTab === 'categories' ? (
                            categories.length > 0 ? (
                                <AgGridReact
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    rowData={categoriesList}
                                    columnDefs={categoriesColumns}
                                    onGridReady={onGridReady}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                    defaultColDef={defaultColDef}
                                    rowHeight={65}
                                    quickFilterText={searchText}
                                    domLayout="autoHeight"
                                />
                            ) : (
                                <h5 className="p-4">There are currently no categories.</h5>
                            )
                        ) : null}
                        {activeTab === 'vehicles' &&
                            vehicles.next &&
                            lastClickedPage === Math.ceil(list.length / 10) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.7rem',
                                        right: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more ({vehicles.count - vehicles.results.length})
                                </Button>
                            )}
                    </div>
                </>
            )}
            <ManageOrganizationModals
                slug={currentOrg?.slug}
                filters={initialFilterValues}
                form={form}
                modal={modal}
                toggle={toggle}
                setForm={setForm}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveFromOrg}
                currentPageNumber={currentPageNumber}
            />
        </div>
    )
}

export default VehiclesCategoriesManage
