import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Button, FormGroup, Label, Container, Input } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { CommonErrors } from 'ui/common'
import { getProfile, loginUser } from 'features/user/actionTypes'
import { LoginSchema } from 'schemas/user'
import { useQuery } from 'utils'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import secureLocalStorage from 'react-secure-storage'
import { selectors } from 'features/dashboard'

const Login = () => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState('loading')
    const [type, setType] = useState('password')
    const query = useQuery(window.location)
    const profile = useSelector(selectors.getUser)
    let history = useHistory()
    const [icon, setIcon] = useState(false)
    const [user, setUser] = useState('')
    const [loggedin, setloggedin] = useState(false)

    const handleSubmit = (data, { setSubmitting }) => {
        setStatus('loading')
        dispatch(loginUser(data)).then(({ error }) => {
            if (!error) {
                setStatus('loaded')
                setSubmitting(false)
                loggedin
                    ? secureLocalStorage.setItem('user', JSON.stringify(data))
                    : secureLocalStorage.removeItem('user')
                const nextPage = query.get('next') || '/dashboard/'
                return (window.location = nextPage)
            } else {
                if (
                    error?.response.data.detail ===
                    'User has MFA enabled Please provide mfa_token for login'
                ) {
                    secureLocalStorage.setItem('user', JSON.stringify(data))
                    if (window.location.pathname === '/') {
                        return (window.location.href = '/login/2fa-code/')
                    } else {
                        return (window.location.href = window.location.pathname + '2fa-code/')
                    }
                }
                setStatus('error')
            }
        })
    }

    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
            setIcon(!icon)
        } else {
            setIcon(!icon)
            setType('password')
        }
    }

    const redirectIfLoggedIn = () => {
        dispatch(getProfile()).then(({ error }) => {
            if (!error) history.push('/dashboard/')
        })
    }

    useEffect(() => {
        profile?.token !== undefined && redirectIfLoggedIn()
        document.title = 'Login to dashboard'
        setStatus('loaded')
        // eslint-disable-next-line
    }, [profile?.token])

    useEffect(() => {
        const loggedInUser = secureLocalStorage.getItem('user')
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser)
            setUser(foundUser)
        }
    }, [])

    return (
        <Container
            className="d-flex flex-column position-relative justify-content-center"
            style={{ height: '600px', width: '390px' }}
        >
            {status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    <span className="text-left">
                        <img
                            width="176px"
                            height="64px"
                            className="text-logo text-white animated-text "
                            src={'/svgs/purple-meili.svg'}
                            alt="logo"
                        />
                    </span>
                    <h4 className="pt-5 login-text">Log in</h4>

                    <small className="font-16" style={{ width: '340px' }}>
                        Welcome back! Please enter your details.
                    </small>
                    <Formik
                        initialValues={{
                            email: user.email,
                            password: user.password,
                            rememberme: loggedin,
                        }}
                        validationSchema={LoginSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting }) => {
                            return (
                                <Form>
                                    <div className="pt-32">
                                        <FormGroup>
                                            <Label className="modals-labels" for="email">
                                                Email
                                            </Label>
                                            <Field
                                                className="modals-inputs"
                                                style={{
                                                    width: '340px',
                                                    height: '40px',
                                                    paddingLeft: '12px',
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px',
                                                }}
                                                type="email"
                                                data-testid="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={values.email || user.email}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels" for="password">
                                                Password
                                            </Label>
                                            <div className="d-flex ">
                                                <Field
                                                    className="modals-inputs"
                                                    type={type}
                                                    data-testid="password"
                                                    style={{
                                                        width: '340px',
                                                        height: '40px',
                                                        paddingLeft: '12px',
                                                        paddingTop: '8px',
                                                        paddingBottom: '8px',
                                                    }}
                                                    name="password"
                                                    placeholder="Enter your password"
                                                    value={values.password || user.password}
                                                />
                                                <span
                                                    style={{ marginLeft: '-1.5rem' }}
                                                    onClick={handleToggle}
                                                >
                                                    {icon ? (
                                                        <img
                                                            width="24px"
                                                            height="24px"
                                                            className="mt-2 pr-2"
                                                            alt="eyeOff"
                                                            src="/svgs/user-icon/eyeOff.svg"
                                                        />
                                                    ) : (
                                                        <img
                                                            width="24px"
                                                            height="24px"
                                                            className="mt-2 pr-2"
                                                            alt="eyeOn"
                                                            src="/svgs/user-icon/eyeOn.svg"
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div
                                        className="d-flex justify-content-between pt-2 pb-3"
                                        style={{ width: '340px' }}
                                    >
                                        <FormGroup check inline>
                                            <Input
                                                type="checkbox"
                                                id="rememberme"
                                                style={{ width: '18px', height: '18px' }}
                                                checked={loggedin}
                                                onChange={() => {
                                                    setloggedin(!loggedin)
                                                    if (loggedin === false) {
                                                        localStorage.clear('user', user)
                                                    }
                                                }}
                                            />
                                            <Label check>Remember me</Label>
                                        </FormGroup>

                                        <Link to="/users/reset-password/" data-testid="link">
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                className="font-14 ml-4 text-meili"
                                            >
                                                Forgot password?
                                            </span>
                                        </Link>
                                    </div>{' '}
                                    <Button
                                        disabled={isSubmitting}
                                        data-testid="btn"
                                        type="submit"
                                        style={{ width: '340px', height: '40px' }}
                                        className="save-btn-modal modals-new-btns mt-32"
                                        color="meili"
                                    >
                                        Log in
                                        {isSubmitting && <LoadingSpinner />}
                                    </Button>
                                    <p className="size-14 text-black weight-400 text-center pt-4">
                                        Don’t have an account?{' '}
                                        <a
                                            href="mailto:info@meilirobots.com"
                                            className="text-meili weight-500"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Contact us
                                        </a>
                                    </p>
                                    <CommonErrors className="alert alert-danger text-center mt-4" />
                                </Form>
                            )
                        }}
                    </Formik>
                </>
            )}
        </Container>
    )
}

export default Login
